<script setup lang="ts">
import LogoLight from '~/assets/apo-logo.png'

defineOptions({
  name: 'VorgangSchliessungComponent',
})

const currentRoute = useRoute()

const loading = ref(false)
const schritt1Container = reactive({ freischaltCode: currentRoute.params.all[2] } as any)
const schritt1ResultContainer = reactive({} as any)
const schritt2Container = reactive({} as any)
const schritt2ResultContainer = reactive({} as any)

const successDates = reactive({} as any)

const rules1 = {
  freischaltCode: { required: requiredWithMessage('Bitte geben Sie den Freischaltcode an.') },
  sourceApoZusaetzlichesMerkmal: { required: requiredWithMessage('Bitte geben Sie die Mitgliedsnummer an.') },
}
const v$1 = useVuelidate(rules1, schritt1Container as any, { $autoDirty: true })

const rules2 = {}
const v$2 = useVuelidate(rules2, schritt2Container as any, { $autoDirty: true })

const step = ref(1)
const consent = ref(false)

async function goto1() {
  step.value = 1
}

async function goto2(isBackwards: boolean) {
  if (!isBackwards) {
    const isFormCorrect = await v$1.value.$validate()

    if (!v$1.value.$error && isFormCorrect) {
      loading.value = true

      const body = {
        ...schritt1Container,
        step: 'FirstStep',
      }

      apipub.schrittAntragSchliessung(body).then((res) => {
        if (res.data.error) {
          loading.value = false
          useModal.handleError(res.data.errorText, undefined)
          return
        }

        Object.assign(schritt1ResultContainer, res.data)
        Object.assign(successDates, {})

        const idArr = Array.from(schritt1ResultContainer.sourceApotheken, x => x.id)
        for (const [key, value] of Object.entries(schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList)) {
          if (idArr.includes(value)) {
            successDates[key] = value
          }
        }

        step.value = 2
        loading.value = false
      }).catch((error: any) => {
        useModal.handleError('Es ist ein Fehler beim Laden aufgetreten.', error)
        loading.value = false
      })
    }
  }
  else {
    step.value = 2
  }
}

async function goto3(isBackwards: boolean) {
  if (!isBackwards) {
    const isFormCorrect = await v$2.value.$validate()

    if (!v$2.value.$error && isFormCorrect) {
      loading.value = true

      const body = {
        firstStepResultContainer: schritt1ResultContainer,
        step: 'SecondStep',
        schliessungVorgang: schritt1ResultContainer.schliessungVorgang,
        successDates,
      }

      apipub.schrittAntragSchliessung(body).then((res) => {
        if (res.data.error) {
          loading.value = false
          useModal.handleError(res.data.errorText, undefined)
          return
        }

        Object.assign(schritt2ResultContainer, res.data)

        step.value = 3
        loading.value = false
      }).catch((error: any) => {
        useModal.handleError('Es ist ein Fehler beim Laden aufgetreten.', error)
        loading.value = false
      })
    }
  }
  else {
    step.value = 2
  }
}
</script>

<template>
  <main text="center dark:gray-200">
    <div class="login-bg h-full w-full flex flex-col items-center overflow-y-auto pb-8 pt-8 dark:bg-gray-900">
      <!-- Card -->
      <div class="max-w-7xl w-full b-5 border-red rounded-lg bg-white p-6 shadow space-y-1 dark:bg-gray-800">
        <a class="mb-4 flex items-center justify-center text-2xl font-semibold dark:text-white">
          <img :src="LogoLight" class="mr-3 h-8 w-8" alt="sberg Notdienstportal Logo">
          <span>Notdienstverteilung: <span class="text-red font-bold underline">Schließung</span> einer Apotheke</span>
        </a>
        <div>
          <!-- Modal body -->
          <div v-if="schritt1Container" class="space-y-6">
            <div class="flex flex-col md:flex-row">
              <ol class="mb-4 mr-4 w-full flex-grow-0 md:min-w-220px md:w-220px space-y-4">
                <li>
                  <Step sr="Apotheke" label="1. Apotheke" :active="step === 1" :done="step > 1" />
                </li>
                <li>
                  <Step sr="Dienste" label="2. Dienste" :active="step === 2" :done="step > 2" />
                </li>
                <li>
                  <Step sr="Eingaben prüfen" label="3. Eingaben prüfen" :active="step === 3" :done="step > 3" />
                </li>
              </ol>

              <Transition>
                <form v-if="step === 1" class="min-0 flex-grow-1">
                  <div class="text-md mb-2 flex items-start justify-start text-left dark:text-white">
                    Willkommen bei der Notdienstverteilung zur Schließung einer Apotheke.<br>Bitte geben Sie den per E-Mail erhaltenen Freischaltcode ein. Dieser ist beim Klicken auf den Link bereits vorausgefüllt.<br>Geben Sie außerdem Ihre persönliche Mitgliedsnummer als Inhaber an.
                  </div>

                  <div class="grid md:grid-cols-1 md:gap-2">
                    <TheInput
                      v-if="schritt1Container"
                      id="freischaltCode"
                      v-model="schritt1Container.freischaltCode"
                      class="p-1 text-align-start"
                      type="text"
                      label="Freischaltcode"
                      required
                      :v$="v$1"
                      :disabled="loading"
                      @keydown.enter.exact.stop.prevent=""
                    />

                    <TheInput
                      v-if="schritt1Container"
                      id="sourceApoZusaetzlichesMerkmal"
                      v-model="schritt1Container.sourceApoZusaetzlichesMerkmal"
                      class="p-1 text-align-start"
                      type="number"
                      label="Mitgliedsnummer des/der Inhabers(in) der Apotheke"
                      help="Bsp.: 123456"
                      required
                      :v$="v$1"
                    />
                  </div>

                  <div class="flex justify-between">
                    <div />
                    <TheButton
                      label="Weiter"
                      icon="mdi-arrow-right"
                      :disabled="loading || v$1.$error || !v$1.$anyDirty"
                      @click="goto2(false)"
                    />
                  </div>
                </form>
              </Transition>

              <Transition>
                <form v-if="step === 2" class="min-0 flex-grow-1 items-start">
                  <div class="text-md mb-1 flex items-start justify-start text-left dark:text-white">
                    In diesem Schritt sehen Sie eine Übersicht ihrer relevanten Apotheken, inklusive der jeweiligen Datumsangaben. Hier haben Sie die Möglichkeit, freiwillig einen Dienst der schließenden Apotheke zu übernehmen. Zur Unterstützung Ihrer Entscheidung werden allgemeine Apothekenstatistiken sowie spezifische Statistiken für jedes Datum angezeigt.
                  </div>

                  <div class="text-md mb-2 flex items-start justify-start text-left font-bold dark:text-white">
                    Achtung: Ihre nachfolgenden Angaben sind verpflichtend, zutreffendes ist anzukreuzen.
                  </div>

                  <form
                    v-if="schritt1ResultContainer && schritt1ResultContainer.targetApotheke && schritt1ResultContainer.sourceApotheken"
                    class="mb-1 flex flex-col gap-2 b-1"
                  >
                    <div class="grid grid-cols-1 mb-0 gap-1">
                      <div>
                        <div class="mb-2 block text-xl text-gray-900 font-bold dark:text-white">
                          Schließende Apotheke:
                        </div>
                        <div class="block text-gray-900 dark:text-white">
                          {{ schritt1ResultContainer.targetApotheke.name }}<br>
                          {{ schritt1ResultContainer.targetApotheke.strasse }}<br>
                          {{ schritt1ResultContainer.targetApotheke.plz }} {{ schritt1ResultContainer.targetApotheke.ort }}<br>
                          Eröffnung: {{ dfNoRef(schritt1ResultContainer.targetApotheke.oeffnungsDatum) }}<br>
                          <!-- Schließung: {{ dfNoRef(schritt1ResultContainer.targetApotheke.schliessungsDatum) }}<br> -->
                        </div>
                      </div>
                    </div>

                    <div class="block text-xl text-gray-900 font-bold dark:text-white">
                      Ihre Apotheken:
                    </div>

                    <template v-if="schritt1ResultContainer && schritt1ResultContainer.sourceApotheken && schritt1ResultContainer.sourceApotheken.length > 0">
                      <div v-for="(sourceApo, sourceApoIndex) in schritt1ResultContainer.sourceApotheken" :key="sourceApo.id" class="pt-2">
                        <div class="grid grid-cols-1 mb-4">
                          <div>
                            <span class="font-bold">{{ sourceApoIndex + 1 }}. {{ sourceApo.name }}</span><br>
                            {{ sourceApo.strasse }}<br>
                            {{ sourceApo.plz }} {{ sourceApo.ort }}<br>
                          </div>
                          <div class="text-center">
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoDistanceMap[sourceApo.id] > -1">
                              Entfernung: {{ (schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoDistanceMap[sourceApo.id] / 1000).toFixed(2) }}km<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceCountMap[sourceApo.id] > -1">
                              Dienstanzahl: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceCountMap[sourceApo.id] }}<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoWeekendServiceCountMap[sourceApo.id] > -1">
                              Anzahl Wochenenddienste: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoWeekendServiceCountMap[sourceApo.id] }}<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoHolidayServiceCountMap[sourceApo.id] > -1">
                              Anzahl Feiertagsdienste: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoHolidayServiceCountMap[sourceApo.id] }}<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoHandoverServiceCountMap[sourceApo.id] > -1">
                              Anzahl abgegebener Dienste: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoHandoverServiceCountMap[sourceApo.id] }}<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoTakeoverServiceCountMap[sourceApo.id] > -1">
                              Anzahl übernommener Dienste: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoTakeoverServiceCountMap[sourceApo.id] }}<br>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoVolunteerCurrentTakeoverServiceCountMap && schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoVolunteerCurrentTakeoverServiceCountMap[sourceApo.id] > -1">
                              Anzahl freiwillig übernommener Dienste: {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoVolunteerCurrentTakeoverServiceCountMap[sourceApo.id] }}<br>
                            </template>
                          </div>
                        </div>

                        <div class="mb-2 block text-gray-900 dark:text-white">
                          <div v-for="date in schritt1ResultContainer.sourceApoDateList[sourceApo.id]" :key="date" class="mb-4 flex flex-col">
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] && schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] === sourceApo.id">
                              <!-- Dieser Dienst ist bereits vergeben für diese Apotheke -->
                              <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                              <div>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                  Letzter Dienst vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                  Nächster Dienst in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                  Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                  Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] > -1">
                                  Entfernung zur nächsten notdiensthabenen Apotheke {{ (schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] / 1000).toFixed(2) }}km<br>
                                </template>
                              </div>
                              <div class="mb-0.5 flex justify-center">
                                <div class="min-0 mr-2 font-bold">
                                  Ich möchte den Dienst am {{ dfNoRef(date) }} in dieser Apotheke übernehmen:
                                </div>
                                <input
                                  :checked="successDates[date] === sourceApo.id"
                                  type="checkbox"
                                  class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                  @change="successDates[date] = $event.target && $event.target.checked === true ? sourceApo.id : undefined"
                                >
                                <!-- span class="w-12 font-bold">({{ successDates[date] === sourceApo.id ? 'Ja' : 'Nein' }})</span -->
                              </div>
                            </template>
                            <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] && schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] !== sourceApo.id">
                              <!-- Dieser Dienst ist bereits vergeben für eine andere Apotheke -->
                              <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                              <div>
                                Dieser Dienst wurde bereits von einer anderen Apotheke reserviert.
                              </div>
                            </template>
                            <template v-if="!schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date]">
                              <!-- Dieser Dienst wurde noch nicht vergeben -->
                              <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                              <div>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                  Letzter Dienst vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                  Nächster Dienst in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                  Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                  Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] > -1">
                                  Entfernung zur nächsten notdiensthabenen Apotheke {{ (schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] / 1000).toFixed(2) }}km<br>
                                </template>
                              </div>
                              <div class="mb-0.5 flex justify-center">
                                <div class="min-0 mr-2 font-bold">
                                  Ich möchte den Dienst am {{ dfNoRef(date) }} in dieser Apotheke übernehmen:
                                </div>
                                <input
                                  :checked="successDates[date] === sourceApo.id"
                                  type="checkbox"
                                  class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                  @change="successDates[date] = $event.target && $event.target.checked === true ? sourceApo.id : undefined"
                                >
                                <!-- span class="w-12 font-bold">({{ successDates[date] === sourceApo.id ? 'Ja' : 'Nein' }})</span -->
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="mb-2 block text-gray-900 dark:text-white">
                        Für diesen Vorgang und ihre/n Apotheke/n sind keine Dienste verfügbar die Sie übernehmen könnten.
                      </div>
                    </template>
                  </form>

                  <div class="mb-2 mt-2 flex self-center justify-around">
                    <div class="self-auto text-lg text-gray-900 font-bold dark:text-white">
                      Ich bestätige hiermit, dass ich die von mir ausgewählten Dienste verpflichtend übernehme.
                    </div>
                    <input v-model="consent" style="min-height:28px;min-width:28px;height:28px;width:28px;align-self: center;" type="checkbox">
                  </div>

                  <div class="flex flex-wrap justify-around gap-row-1 md:justify-between">
                    <TheButton
                      label="Zurück"
                      icon="mdi-arrow-left"
                      :disabled="loading"
                      @click="goto1"
                    />

                    <TheButton
                      label="Weiter & Angaben speichern"
                      icon="mdi-arrow-right"
                      :disabled="loading || !schritt1ResultContainer || !schritt1ResultContainer.sourceApotheken || schritt1ResultContainer.sourceApotheken.length <= 0 || !consent"
                      @click="goto3(false)"
                    />
                  </div>
                </form>
              </Transition>

              <Transition>
                <form v-if="step === 3" class="min-0 flex-grow-1">
                  <div class="text-md mb-8 flex flex-col items-start justify-start text-left font-semibold dark:text-white">
                    <div class="grid grid-cols-1 gap-1">
                      Ihre Angaben wurden erfolgreich gespeichert.
                    </div>

                    <div v-if="schritt2ResultContainer.dateReserved && schritt2ResultContainer.dateReserved.length > 0" class="grid grid-cols-1 gap-1">
                      Folgende Daten konnten nicht reserviert, da keiner der Termine mehr verfügbar ist:<br>
                      <br>
                      <div v-for="(item, dateRes) in schritt2ResultContainer.dateReserved" :key="dateRes" class="block text-gray-900 dark:text-white">
                        <span class="font-bold">{{ dfWeekdayLongNoRef(dateRes) }}, {{ dfNoRef(dateRes) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[dateRes] === true ? ', Feiertag' : '' }}</span>
                      </div>
                    </div>
                    <div class="grid grid-cols-1 gap-1">
                      Folgende Daten wurden für Sie reserviert:<br>
                      <br>

                      <template v-for="(item, sucDate) in successDates" :key="sucDate">
                        <div v-if="!schritt2ResultContainer.dateReserved.includes(sucDate) && item" class="block text-gray-900 dark:text-white">
                          <span class="font-bold">{{ dfWeekdayLongNoRef(sucDate) }}, {{ dfNoRef(sucDate) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[sucDate] === true ? ', Feiertag' : '' }}</span>
                        </div>
                      </template>
                    </div>
                    <br>
                    Vielen Dank für Ihre Unterstützung. Sie können diese Seite nun schließen.
                    <br>
                  </div>
                </form>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
