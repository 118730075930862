import * as chroma from 'chroma.ts'
import * as typeFileLocal from '~/types'

export function generateColors(size: number) {
  return chroma.scale('Spectral').classes(size).colors(size)
}

export function setToDefault(obj: any) {
  // TODO
  /*
  -> generischer weg object zu säubern
  1. default values
  2. undefined wo keine value
  3. leere unterobjekte -> kreislauf
  */

  // Object.assign(antragContainer, {})
  // const baseObj = {} as NotdienstAenderungAntragDetailContainer
  const props = Object.getOwnPropertyNames(obj)
  for (let i = 0; i < props.length; i++) {
    const key = props[i]! as keyof typeof obj
    // const value = baseObj[key] ?? undefined
    // antragContainer[key] = value ?? undefined
    obj[key] = undefined
  }
}

// '03.03.2025'
export const df = Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeZone: 'Europe/Berlin' })
// '03.03.2025, 16:31'
export const dtf = Intl.DateTimeFormat('de-DE', { dateStyle: 'medium', timeStyle: 'short', timeZone: 'Europe/Berlin' })
// 'Mo'
export const dfWeekday = Intl.DateTimeFormat('de-DE', { weekday: 'short', timeZone: 'Europe/Berlin' })
// 'Montag'
export const dfWeekdayLong = Intl.DateTimeFormat('de-DE', { weekday: 'long', timeZone: 'Europe/Berlin' })
// '16:32'
export const tf = Intl.DateTimeFormat('de-DE', { timeStyle: 'short', timeZone: 'Europe/Berlin' })

// REF
// Spezialfall für z.B. Tabelle
export function dfRef(params: any) {
  if (!params.value)
    return '-'
  const dateObj = new Date(params.value)
  return df.format(dateObj)
}
export function dtfRef(params: any) {
  if (!params.value)
    return '-'
  const dateObj = new Date(params.value)
  return dtf.format(dateObj)
}
export function dfWeekdayRef(params: any) {
  if (!params.value)
    return '-'
  const dateObj = new Date(params.value)
  return dfWeekday.format(dateObj)
}

// NO REF
export function dfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return df.format(dateObj)
}

export function dtfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dtf.format(dateObj)
}

export function dfWeekdayNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dfWeekday.format(dateObj)
}

export function dfWeekdayLongNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return dfWeekdayLong.format(dateObj)
}

export function tfNoRef(params: any) {
  if (!params)
    return '-'
  const dateObj = new Date(params)
  return tf.format(dateObj)
}

export const typeFile = typeFileLocal
