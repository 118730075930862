<script lang="ts" setup>
import { ref } from 'vue'

defineProps({
  label: String,
  route: String,
  icon: String,
})

const router = useRouter()
const showPanel = ref(false)

const activeRoute = computed(() => {
  const matchedRoutes = router.currentRoute.value.matched
  const lastRoute = matchedRoutes[matchedRoutes.length - 1]
  return lastRoute.name
})

function togglePanel() {
  showPanel.value = !showPanel.value
}
</script>

<template>
  <div class="w-full">
    <button
      class="group mb-2 w-full flex items-center rounded-lg p-2 text-base text-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
      :class="activeRoute === route || activeRoute && route && activeRoute?.toString().startsWith(`detail-${route}-id`) ? 'bg-gray-200 dark:bg-gray-700' : ''"
      @click.prevent="togglePanel"
    >
      <div
        :i="icon"
        class="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
      />
      <span class="ml-3" sidebar-toggle-item>{{ label }}</span>
      <span class="w-full" />
      <div
        :i="showPanel ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        class="h-6 w-6 flex-shrink-0 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
        fill="currentColor"
        viewBox="0 0 20 20"
        aria-hidden="true"
      />
    </button>
    <div v-if="showPanel" class="content flex flex-col">
      <slot />
    </div>
  </div>

  <!-- router-link
    class="group flex items-center rounded-lg p-2 text-base text-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700"
    :class="activeRoute === route || activeRoute && route && activeRoute?.toString().startsWith(`detail-${route}-id`) ? 'bg-gray-200 dark:bg-gray-700' : ''"
    :to="targetRoute ? targetRoute.fullPath : '/'"
  >
    <div
      :i="icon"
      class="h-6 w-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      aria-hidden="true"
    />
    <span class="ml-3" sidebar-toggle-item>{{ label }}</span>
  </router-link -->
</template>
