import type { AxiosResponse } from 'axios'
import axios from 'axios'

// const baseUrl = import.meta.env.DEV ? 'http://localhost:8084/' : '/api/'
const baseUrl = '/api/'
const prefix = 'prtpub'

function getConfig() {
  const userStore = useUserStore()
  const config = {
    headers: {
      Authorization: '',
    },
  }

  if (userStore.token)
    config.headers.Authorization = `Bearer ${userStore.token}`

  return config
}

export const apipub = {
  // Schliessungsvorgang
  schrittAntragSchliessung(obj: any): Promise<AxiosResponse<any>> {
    return axios.post(`${baseUrl}${prefix}/schliessungvorgang/umfrageschritt`, obj, getConfig())
  },
  // Antragsvorgang
  schrittAntragVorgang(obj: any): Promise<AxiosResponse<any>> {
    return axios.post(`${baseUrl}${prefix}/antragvorgang/umfrageschritt`, obj, getConfig())
  },
}
