<script setup lang="ts">
import LogoLight from '~/assets/apo-logo.png'

defineOptions({
  name: 'VorgangAntragComponent',
})

const currentRoute = useRoute()

const formatEnum: (value: string, type: string) => string = inject('$formatEnum')!

const loading = ref(false)
const schritt1Container = reactive({ freischaltCode: currentRoute.params.all[2] } as any)
const schritt1ResultContainer = reactive({} as any)
const schritt2Container = reactive({} as any)
const schritt2ResultContainer = reactive({} as any)

const successDates = reactive({} as any)
const successDate = ref(undefined)
const successApoId = ref(undefined)
const offeredDates = ref<any[]>([])

const rules1 = {
  freischaltCode: { required: requiredWithMessage('Bitte geben Sie den Freischaltcode an.') },
  sourceApoZusaetzlichesMerkmal: { required: requiredWithMessage('Bitte geben Sie die Mitgliedsnummer an.') },
}
const v$1 = useVuelidate(rules1, schritt1Container as any, { $autoDirty: true })

const rules2 = {}
const v$2 = useVuelidate(rules2, schritt2Container as any, { $autoDirty: true })

const step = ref(1)
const consent = ref(false)

function setSuccess(d: any, i: any) {
  successDate.value = d
  successApoId.value = i
}

function setOffered(obj: any, remove: boolean) {
  if (remove) {
    offeredDates.value.splice(offeredDates.value.indexOf(obj), 1)
  }
  else {
    offeredDates.value.push(obj)
  }
}

function clearOffered() {
  offeredDates.value = []
}

async function goto1() {
  step.value = 1
}

async function goto2(isBackwards: boolean) {
  if (!isBackwards) {
    const isFormCorrect = await v$1.value.$validate()

    if (!v$1.value.$error && isFormCorrect) {
      loading.value = true

      const body = {
        ...schritt1Container,
        step: 'FirstStep',
      }

      apipub.schrittAntragVorgang(body).then((res) => {
        if (res.data.error) {
          loading.value = false
          useModal.handleError(res.data.errorText, undefined)
          return
        }

        Object.assign(schritt1ResultContainer, res.data)
        Object.assign(successDates, {})

        const idArr = Array.from(schritt1ResultContainer.targetApotheken, x => x.id)
        for (const [key, value] of Object.entries(schritt1ResultContainer.antragVorgang.antragVorgangDetails.successTargetApoList)) {
          if (idArr.includes(value)) {
            successDates[key] = value
          }
        }

        step.value = 2
        loading.value = false
      }).catch((error: any) => {
        useModal.handleError('Es ist ein Fehler beim Laden aufgetreten.', error)
        loading.value = false
      })
    }
  }
  else {
    step.value = 2
  }
}

async function goto3(isBackwards: boolean) {
  if (!isBackwards) {
    const isFormCorrect = await v$2.value.$validate()

    if (!v$2.value.$error && isFormCorrect) {
      loading.value = true

      const body = {
        firstStepResultContainer: schritt1ResultContainer,
        step: 'SecondStep',
        antragVorgang: schritt1ResultContainer.antragVorgang,
        successDate: schritt1ResultContainer.targetApoContext ? schritt1ResultContainer.antragVorgang.datum : successDate.value,
        successApoId: schritt1ResultContainer.targetApoContext ? successDates[schritt1ResultContainer.antragVorgang.datum] : successApoId.value,
        offeredDates: schritt1ResultContainer.targetApoContext ? offeredDates.value : undefined,
      }

      apipub.schrittAntragVorgang(body).then((res) => {
        if (res.data.error) {
          loading.value = false
          useModal.handleError(res.data.errorText, undefined)
          return
        }

        Object.assign(schritt2ResultContainer, res.data)

        step.value = 3
        loading.value = false
      }).catch((error: any) => {
        useModal.handleError('Es ist ein Fehler beim Laden aufgetreten.', error)
        loading.value = false
      })
    }
  }
  else {
    step.value = 2
  }
}
</script>

<template>
  <main text="center dark:gray-200">
    <div class="login-bg h-full w-full flex flex-col items-center overflow-y-auto pb-8 pt-8 dark:bg-gray-900">
      <!-- Card -->
      <div class="max-w-7xl w-full b-5 border-blue-800 rounded-lg bg-white p-6 shadow space-y-1 dark:bg-gray-800">
        <a class="mb-4 flex items-center justify-center text-2xl font-semibold dark:text-white">
          <img :src="LogoLight" class="mr-3 h-8 w-8" alt="sberg Notdienstportal Logo">
          <span>Notdienstverteilung: <span class="text-blue-800 font-bold underline">Änderungsantrag</span> einer Apotheke</span>
        </a>
        <div>
          <!-- Modal body -->
          <div v-if="schritt1Container" class="space-y-6">
            <div class="flex flex-col md:flex-row">
              <ol class="mb-4 mr-4 w-full flex-grow-0 md:min-w-220px md:w-220px space-y-4">
                <li>
                  <Step sr="Apotheke" label="1. Apotheke" :active="step === 1" :done="step > 1" />
                </li>
                <li>
                  <Step sr="Dienste" label="2. Dienste" :active="step === 2" :done="step > 2" />
                </li>
                <li>
                  <Step sr="Eingaben prüfen" label="3. Eingaben prüfen" :active="step === 3" :done="step > 3" />
                </li>
              </ol>

              <Transition>
                <form v-if="step === 1" class="min-0 flex-grow-1">
                  <div class="text-md mb-2 flex items-start justify-start text-left dark:text-white">
                    Willkommen bei der Notdienstverteilung zur Verteilung eines Dienstes einer Apotheke.<br>Bitte geben Sie den per E-Mail erhaltenen Freischaltcode ein. Dieser ist beim Klicken auf den Link bereits vorausgefüllt.<br>Geben Sie außerdem Ihre persönliche Mitgliedsnummer als Inhaber an.
                  </div>

                  <div class="grid md:grid-cols-1 md:gap-2">
                    <TheInput
                      v-if="schritt1Container"
                      id="freischaltCode"
                      v-model="schritt1Container.freischaltCode"
                      class="p-1 text-align-start"
                      type="text"
                      label="Freischaltcode"
                      required
                      :v$="v$1"
                      :disabled="loading"
                      @keydown.enter.exact.stop.prevent=""
                    />

                    <TheInput
                      v-if="schritt1Container"
                      id="sourceApoZusaetzlichesMerkmal"
                      v-model="schritt1Container.sourceApoZusaetzlichesMerkmal"
                      class="p-1 text-align-start"
                      type="number"
                      label="Mitgliedsnummer des/der Inhabers(in) der Apotheke"
                      help="Bsp.: 123456"
                      required
                      :v$="v$1"
                    />
                  </div>

                  <div class="flex justify-between">
                    <div />
                    <TheButton
                      label="Weiter"
                      icon="mdi-arrow-right"
                      :disabled="loading || v$1.$error || !v$1.$anyDirty"
                      @click="goto2(false)"
                    />
                  </div>
                </form>
              </Transition>

              <Transition>
                <form v-if="step === 2" class="min-0 flex-grow-1 items-start">
                  <div class="text-md mb-1 flex items-start justify-start text-left dark:text-white">
                    In diesem Schritt sehen Sie eine Übersicht ihrer relevanten Apotheken, inklusive der jeweiligen Datumsangaben. Hier haben Sie die Möglichkeit, freiwillig einen Dienst der Ausgangs-Apotheke zu übernehmen. Zur Unterstützung Ihrer Entscheidung werden allgemeine Apothekenstatistiken sowie spezifische Statistiken für das Datum angezeigt.
                  </div>

                  <div class="text-md mb-2 flex items-start justify-start text-left font-bold dark:text-white">
                    Achtung: Ihre nachfolgenden Angaben sind verpflichtend, zutreffendes ist anzukreuzen.
                  </div>

                  <form
                    v-if="schritt1ResultContainer && schritt1ResultContainer.sourceApotheke && schritt1ResultContainer.targetApotheken"
                    class="mb-1 flex flex-col gap-2 b-1"
                  >
                    <div class="grid grid-cols-1 mb-0 gap-1">
                      <div>
                        <div class="mb-2 block text-2xl text-gray-900 font-bold dark:text-white">
                          Ausgangs-Apotheke:
                        </div>
                        <div class="block text-gray-900 dark:text-white">
                          {{ schritt1ResultContainer.sourceApotheke.name }}<br>
                          {{ schritt1ResultContainer.sourceApotheke.strasse }}<br>
                          {{ schritt1ResultContainer.sourceApotheke.plz }} {{ schritt1ResultContainer.sourceApotheke.ort }}<br>
                          Tel.: {{ schritt1ResultContainer.sourceApotheke.telefon }}<br>
                        </div>

                        <div v-if="schritt1ResultContainer.sourceApoContext" class="text-center">
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServiceCountMap[schritt1ResultContainer.sourceApotheke.id] > -1">
                            Dienstanzahl: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServiceCountMap[schritt1ResultContainer.sourceApotheke.id] }}<br>
                          </template>
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoWeekendServiceCountMap[schritt1ResultContainer.sourceApotheke.id] > -1">
                            Anzahl Wochenenddienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoWeekendServiceCountMap[schritt1ResultContainer.sourceApotheke.id] }}<br>
                          </template>
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHolidayServiceCountMap[schritt1ResultContainer.sourceApotheke.id] > -1">
                            Anzahl Feiertagsdienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHolidayServiceCountMap[schritt1ResultContainer.sourceApotheke.id] }}<br>
                          </template>
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap && schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap[schritt1ResultContainer.sourceApotheke.id] > -1">
                            Anzahl übernommener Dienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap[schritt1ResultContainer.sourceApotheke.id] }}<br>
                          </template>
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHandoverServiceCountMap[schritt1ResultContainer.sourceApotheke.id] > -1">
                            Anzahl abgegebener Dienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHandoverServiceCountMap[schritt1ResultContainer.sourceApotheke.id] }}<br>
                          </template>

                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] > -1">
                            Letzter Dienst vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                          </template>
                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] > -1">
                            Nächster Dienst in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                          </template>

                          <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoBranchNetwork[schritt1ResultContainer.sourceApotheke.id] === true">
                            <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] > -1">
                              Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                            </template>
                            <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] > -1">
                              Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                            </template>
                          </template>
                        </div>

                        <div class="mb-2 block text-xl text-gray-900 font-bold dark:text-white">
                          {{ formatEnum(schritt1ResultContainer.antragVorgang.typ, 'EnumNotdienstAenderungAntragTyp') }} am {{ dfWeekdayLongNoRef(schritt1ResultContainer.antragVorgang.datum) }}, {{ dfNoRef(schritt1ResultContainer.antragVorgang.datum) }}{{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}
                        </div>
                      </div>
                    </div>

                    <div v-if="schritt1ResultContainer.targetApoContext">
                      <div class="block text-xl text-gray-900 font-bold dark:text-white">
                        Ihre Apotheken:
                      </div>
                      <template v-if="schritt1ResultContainer && schritt1ResultContainer.targetApotheken && Object.values(schritt1ResultContainer.targetApotheken).length > 0">
                        <div v-for="(targetApo, targetApoIndex) in Object.values(schritt1ResultContainer.targetApotheken)" :key="targetApo.id" class="pt-2">
                          <div class="grid grid-cols-1 mb-4">
                            <div>
                              <span class="font-bold">{{ targetApoIndex + 1 }}. {{ targetApo.name }}</span><br>
                              {{ targetApo.strasse }}<br>
                              {{ targetApo.plz }} {{ targetApo.ort }}<br>
                            </div>

                            <br>

                            <div class="text-center">
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServiceCountMap[targetApo.id] > -1">
                                Dienstanzahl: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServiceCountMap[targetApo.id] }}<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoWeekendServiceCountMap[targetApo.id] > -1">
                                Anzahl Wochenenddienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoWeekendServiceCountMap[targetApo.id] }}<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHolidayServiceCountMap[targetApo.id] > -1">
                                Anzahl Feiertagsdienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHolidayServiceCountMap[targetApo.id] }}<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap && schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap[targetApo.id] > -1">
                                Anzahl übernommener Dienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoTakeoverServiceCountMap[targetApo.id] }}<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHandoverServiceCountMap[targetApo.id] > -1">
                                Anzahl abgegebener Dienste: {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoHandoverServiceCountMap[targetApo.id] }}<br>
                              </template>

                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] > -1">
                                Letzter Dienst vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] }} Tagen<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] > -1">
                                Nächster Dienst in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] }} Tagen<br>
                              </template>

                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoBranchNetwork[targetApo.id] === true">
                                <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] > -1">
                                  Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] > -1">
                                  Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[schritt1ResultContainer.antragVorgang.datum][targetApo.id] }} Tagen<br>
                                </template>
                              </template>
                            </div>

                            <br>

                            <div class="mb-0.5 flex justify-center">
                              <div class="min-0 mr-2 font-bold">
                                Ich möchte den Dienst am {{ dfNoRef(schritt1ResultContainer.antragVorgang.datum) }} in dieser Apotheke übernehmen:
                              </div>
                              <input
                                :checked="successDates[schritt1ResultContainer.antragVorgang.datum] === targetApo.id"
                                type="checkbox"
                                class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                @change="successDates[schritt1ResultContainer.antragVorgang.datum] = $event.target && $event.target.checked === true ? targetApo.id : undefined"
                                @click="clearOffered"
                              >
                            </div>

                            <div v-if="successDates[schritt1ResultContainer.antragVorgang.datum] === targetApo.id" class="mb-0.5 flex flex-col justify-center">
                              <div class="min-0 mr-2 font-bold">
                                Ich möchte die folgenden Dienste als mögliche Rücktauschdaten anbieten:
                              </div>

                              <div v-for="(rueckDate) in schritt1ResultContainer.antragVorgang.antragVorgangDetails.targetApoRuecktauschDatumList[targetApo.id]" :key="`${targetApo.id}-${rueckDate}`" class="flex justify-center">
                                <div class="min-0 mr-2 font-bold w-300px">
                                  {{ dfWeekdayLongNoRef(rueckDate) }}, {{ dfNoRef(rueckDate) }}{{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.holidayMap[rueckDate] === true ? ', Feiertag' : '' }}
                                </div>
                                <input
                                  :checked="offeredDates.includes(rueckDate)"
                                  type="checkbox"
                                  class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                  @change="$event.target && $event.target.checked === true ? setOffered(rueckDate, false) : setOffered(rueckDate, true)"
                                >
                              </div>
                            </div>
                          </div>

                          <!-- div class="mb-2 block text-gray-900 dark:text-white">
                            <div v-for="date in schritt1ResultContainer.sourceApoDateList[targetApo.id]" :key="date" class="mb-4 flex flex-col">
                              <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] && schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] === sourceApo.id">
                                <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                                <div class="mb-0.5 flex justify-center">
                                  <div class="min-0 mr-2 font-bold">
                                    Ich möchte den Dienst am {{ dfNoRef(date) }} in dieser Apotheke übernehmen:
                                  </div>
                                  <input
                                    :checked="successDates[date] === sourceApo.id"
                                    type="checkbox"
                                    class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                    @change="successDates[date] = $event.target && $event.target.checked === true ? sourceApo.id : undefined"
                                  >
                                </div>
                              </template>
                              <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] && schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date] !== sourceApo.id">
                                <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                                <div>
                                  Dieser Dienst wurde bereits von einer anderen Apotheke reserviert.
                                </div>
                              </template>
                              <template v-if="!schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.successTargetApoList[date]">
                                <span class="font-bold">{{ dfWeekdayLongNoRef(date) }}, {{ dfNoRef(date) }}{{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.holidayMap[date] === true ? ', Feiertag' : '' }}</span>
                                <div>
                                  <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                    Letzter Dienst vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                  </template>
                                  <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                    Nächster Dienst in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                  </template>
                                  <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] > -1">
                                    Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePreRestCountMap[date][sourceApo.id] }} Tagen<br>
                                  </template>
                                  <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] > -1">
                                    Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetBranchNetworkApoServicePostRestCountMap[date][sourceApo.id] }} Tagen<br>
                                  </template>
                                  <template v-if="schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] > -1">
                                    Entfernung zur nächsten notdiensthabenen Apotheke {{ (schritt1ResultContainer.schliessungVorgang.schliessungVorgangDetails.targetApoServiceDeputyDistanceMap[date][sourceApo.id] / 1000).toFixed(2) }}km<br>
                                  </template>
                                </div>
                                <div class="mb-0.5 flex justify-center">
                                  <div class="min-0 mr-2 font-bold">
                                    Ich möchte den Dienst am {{ dfNoRef(date) }} in dieser Apotheke übernehmen:
                                  </div>
                                  <input
                                    :checked="successDates[date] === sourceApo.id"
                                    type="checkbox"
                                    class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                    @change="successDates[date] = $event.target && $event.target.checked === true ? sourceApo.id : undefined"
                                  >
                                </div>
                              </template>
                            </div>
                          </div -->
                        </div>
                      </template>
                      <template v-else>
                        <div class="mb-2 block text-gray-900 dark:text-white">
                          Für diesen Vorgang und ihre/n Apotheke/n sind keine Dienste verfügbar die Sie übernehmen könnten.
                        </div>
                      </template>
                    </div>

                    <div v-if="schritt1ResultContainer.sourceApoContext">
                      <div class="block text-xl text-gray-900 font-bold dark:text-white">
                        Die folgenden Tauschkandidaten sind verfügbar:
                      </div>

                      <template v-if="schritt1ResultContainer && schritt1ResultContainer.antragVorgang.antragVorgangDetails.successTargetApoList && schritt1ResultContainer.antragVorgang.antragVorgangDetails.successTargetApoList.length > 0">
                        <div v-for="(targetApoId, targetApoIndex) in schritt1ResultContainer.antragVorgang.antragVorgangDetails.successTargetApoList" :key="targetApoId" class="pt-2">
                          <div class="grid grid-cols-1 mb-4">
                            <div>
                              <span class="font-bold">{{ targetApoIndex + 1 }}. {{ schritt1ResultContainer.targetApotheken[targetApoId].name }}</span><br>
                              {{ schritt1ResultContainer.targetApotheken[targetApoId].strasse }}<br>
                              {{ schritt1ResultContainer.targetApotheken[targetApoId].plz }} {{ schritt1ResultContainer.targetApotheken[targetApoId].ort }}<br>
                              Tel.: {{ schritt1ResultContainer.targetApotheken[targetApoId].telefon }}<br>
                            </div>

                            <br>

                            <div class="font-bold">
                              Die folgenden Rücktauschdaten sind für diese Apotheke verfügbar:
                            </div>

                            <div v-for="(targetDate) in schritt1ResultContainer.antragVorgang.antragVorgangDetails.targetApoAngeboteneRuecktauschDatumList[targetApoId]" :key="targetDate" class="pt-2">
                              <span class="font-bold">{{ dfNoRef(targetDate) }}</span><br>

                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] > -1">
                                Letzter Dienst vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePreRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                              </template>
                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] > -1">
                                Nächster Dienst in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoServicePostRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                              </template>

                              <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.apoBranchNetwork[schritt1ResultContainer.sourceApotheke.id] === true">
                                <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] > -1">
                                  Letzter Dienst einer Filialverbunds-Apotheke vor {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePreRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                                </template>
                                <template v-if="schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] > -1">
                                  Nächster Dienst einer Filialverbunds-Apotheke in {{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.branchNetworkApoServicePostRestCountMap[targetDate][schritt1ResultContainer.sourceApotheke.id] }} Tagen<br>
                                </template>
                              </template>

                              <div class="mb-0.5 flex justify-center">
                                <div class="min-0 mr-2 font-bold">
                                  Ich möchte den Rücktausch am {{ dfNoRef(targetDate) }} für diese Apotheke übernehmen:
                                </div>
                                <input
                                  :checked="successDate === targetDate && successApoId === targetApoId"
                                  type="checkbox"
                                  class="form-check-input property sberg-mr-2 min-w-30px w-30px"
                                  @change="setSuccess(targetDate, targetApoId)"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="mb-2 block text-gray-900 dark:text-white">
                          {{ schritt1ResultContainer.antragVorgang.typ === 'TAUSCH' ? 'Für diesen Vorgang liegen noch keine möglichen Tauschkandiddaten bzw. Rückmeldungen der Ziel-Apotheken vor.' : 'Es handelt sich um eine Übernahme.' }}
                        </div>
                      </template>
                    </div>
                  </form>

                  <div class="mb-2 mt-2 flex self-center justify-around">
                    <div class="self-auto text-lg text-gray-900 font-bold dark:text-white">
                      Ich bestätige hiermit, dass ich den von mir ausgewählten Dienst verpflichtend übernehme.
                    </div>
                    <input v-model="consent" style="min-height:28px;min-width:28px;height:28px;width:28px;align-self: center;" type="checkbox">
                  </div>

                  <div class="flex flex-wrap justify-around gap-row-1 md:justify-between">
                    <TheButton
                      label="Zurück"
                      icon="mdi-arrow-left"
                      :disabled="loading"
                      @click="goto1"
                    />

                    <TheButton
                      label="Weiter & Angaben speichern"
                      icon="mdi-arrow-right"
                      :disabled="loading || !schritt1ResultContainer || !consent || (schritt1ResultContainer.sourceApoContext && (!successDate || !successApoId)) || (schritt1ResultContainer.targetApoContext && (!successDates[schritt1ResultContainer.antragVorgang.datum] || offeredDates.length === 0))"
                      @click="goto3(false)"
                    />
                  </div>
                </form>
              </Transition>

              <Transition>
                <form v-if="step === 3" class="min-0 flex-grow-1">
                  <div class="text-md mb-8 flex flex-col items-start justify-start text-left font-semibold dark:text-white">
                    <div class="grid grid-cols-1 gap-1">
                      Ihre Angaben wurden erfolgreich gespeichert.
                    </div>

                    <div v-if="schritt1ResultContainer.targetApoContext" class="grid grid-cols-1 gap-1">
                      Folgende Daten wurden für Sie reserviert:<br>
                      <br>

                      {{ formatEnum(schritt1ResultContainer.antragVorgang.typ, 'EnumNotdienstAenderungAntragTyp') }} am {{ dfWeekdayLongNoRef(schritt1ResultContainer.antragVorgang.datum) }}, {{ dfNoRef(schritt1ResultContainer.antragVorgang.datum) }}{{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.holidayMap[schritt1ResultContainer.antragVorgang.datum] === true ? ', Feiertag' : '' }}

                      <br>
                      <br>
                      Folgende Rücktauschdaten werden der Ausgangs-Apotheke von Ihnen angeboten:<br>
                      <br>
                      <span v-for="(targetDate) in offeredDates" :key="targetDate">
                        {{ dfWeekdayLongNoRef(targetDate) }}, {{ dfNoRef(targetDate) }}{{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.holidayMap[targetDate] === true ? ', Feiertag' : '' }}
                      </span>
                    </div>
                    <div v-else class="grid grid-cols-1 gap-1">
                      Folgende Daten wurden für Sie reserviert:<br>
                      <br>

                      Rücktausch am {{ dfWeekdayLongNoRef(successDate) }}, {{ dfNoRef(successDate) }}{{ schritt1ResultContainer.antragVorgang.antragVorgangDetails.holidayMap[successDate] === true ? ', Feiertag' : '' }}
                    </div>
                    <br>
                    Vielen Dank für Ihre Unterstützung. Sie können diese Seite nun schließen.
                    <br>
                  </div>
                </form>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
